import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const DropdownPK = ({ onSelect }) => {
  const [selectedOptionPackage, setSelectedOptionPackage] = useState(null);
  const [packageList, setPackage] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.appzap.la:9100/packages');
        const jsonData = await response.json();
        setPackage(
          jsonData.map((item) => ({
            value: item._id,
            label: item.name
          }))
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 minute

    return () => clearInterval(interval); // Cleanup function to clear interval
  }, []);

  const handleChangePK = (selectedOptionPK) => {
    setSelectedOptionPackage(selectedOptionPK);
    onSelect(selectedOptionPK);
  };

  return (
    <Select
      placeholder="ແພັກເກັດ ..."
      value={selectedOptionPackage}
      onChange={handleChangePK}
      options={packageList}
      isSearchable // Enables search functionality
    />
  );
};

export default DropdownPK;
