// App.js

import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Store from "./pages/store";
import Package from "./pages/package";
import Login from "./pages/Login";

function App() {
  const Token = localStorage.getItem("Token");
  return (
    <BrowserRouter>
      <Routes>
      {Token && <Route path="/" exact element={<Store />}></Route>}
      {Token && <Route path="/packages/:storeId" exact element={<Package />}></Route>}
        <Route path="/login" exact element={<Login />} />
        <Route path="/" exact element={<Navigate replace to="login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
