// Login.js

import React, { useState } from "react";
import "./style.css";

const Login =  ()  =>  {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
        console.log("step 1")
        const response = await fetch("https://api.appzap.la:9100/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const token = data.accessToken;
          localStorage.setItem("Token", token); 
          window.location = "/";
        } else {
          // Authentication failed
          const errorData = await response.json();
          setError(errorData.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        setError("ຊື່ ຫຼື ລະຫັດບໍ່ຕ້ອງ");
      }
  };

  return (
    <div className="login-container">
      <h2><img src="LogoAppZap.png" alt="Image 1" width={"120px"}/></h2>
      <h2>ລະບົບຈັດການແພັກເກັດ</h2>
        <div className="input-group">
          <input
            type="text"
            placeholder="ຊື່"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="ລະຫັດ"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            width={"100px"}
          />
        </div>
        <button onClick={handleLogin} className="button-login">ເຂົ້າສູ່ບົບ</button>
      {error && <p style={{ color: "red", padding: "10px 10px" , textAlign:"center" }}>{error}</p>}
    </div>
  );
};

export default Login;
