import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import DropdownPK from "../component/DropDownPK";
import DropdownSTT from "../component/DropDownSTT";
function Store() {
  const Token = localStorage.getItem("Token");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [dateFrom, setDatefrom] = useState("");
  const [dateTo, setDateto] = useState("");
  const [packageId, setPackageId] = useState("");
  const [status, setStatus] = useState("");
  const handleLogout = () => {
    localStorage.removeItem("Token");
    window.location.reload();
  };
  useEffect(() => {
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 minute
    return () => clearInterval(interval); // Cleanup function to clear interval
  }, [search, packageId, status, dateFrom, dateTo]);
  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://api.appzap.la:9100/stores?name=${search}&packageId=${packageId}&status=${status}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
        {
          headers: {
            Authorization: `STORE ${Token}`,
          },
        }
      );
      const jsonData = await response.json();
      setData(
        jsonData.map((item) => ({
          ...item,
          active: calculateTimeElapsed(item.active),
        }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const calculateTimeElapsed = (LastActive) => {
    const lastactiveDateTime = new Date(LastActive);
    const currentDateTime = new Date();
    const elapsedTime = currentDateTime - lastactiveDateTime;

    const minutes = Math.floor(elapsedTime / (1000 * 60));
    if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    }

    const days = Math.floor(hours / 24);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("csv", file);

    try {
      const response = await fetch("https://api.appzap.la:9100/uploadfile", {
        method: "POST",
        headers: {
          Authorization: `STORE ${Token}`, // Include token in the Authorization header
        },
        body: formData,
      });

      if (response.ok) {
        alert("File uploaded successfully!");

        fetchData(); // Fetch data after successful upload
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      // Handle error
    }
  };
  const handleReset = () => {
    setSearch("");
    setDatefrom("");
    setDateto("");
    setPackageId("");
    setStatus("");
    fetchData(); // Fetch data after resetting
  };

  const handleSelectPK = (selectedOptionPK) => {
    setPackageId(selectedOptionPK.value);
  };
  const handleSelectSTT = (selectedOptionSTT) => {
    setStatus(selectedOptionSTT.value);
  };
  return (
    <div className="container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ fontSize: "25px", fontWeight: "bold" }}>
            ລະບົບຈັດການແພັກເກັດ
          </div>
          <div
            style={{
              height: "2px",
              width: "100px",
              backgroundColor: "orange",
              marginBottom: "20px",
            }}
          ></div>
        </div>
        <div>
          <button onClick={handleLogout}>ອອກຈາກລະບົບ</button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <br />
        <label htmlFor="csv">ອັບໂຫລດ(csv)</label>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <input
              type="file"
              id="csv"
              name="csv"
              accept=".csv"
              onChange={handleFileChange}
            />
            <br />
            <br />
            <button type="submit">Upload CSV</button>
          </div>
          <div>
            <button className="reset-button" onClick={handleReset}>
              Reset
            </button>
            <br />
            <br />
            <input
              className="date-input"
              type="date"
              value={dateFrom}
              onChange={(e) => setDatefrom(e.target.value)}
            />
            <span> --- </span>
            <input
              className="date-input"
              type="date"
              value={dateTo}
              onChange={(e) => setDateto(e.target.value)}
            />
            <br />
            <br />
            <input
              className="search-input"
              type="text"
              placeholder="ຊື່ຮ້ານ ..."
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <DropdownPK onSelect={handleSelectPK} />
            <br />
            <DropdownSTT onSelect={handleSelectSTT} />
          </div>
        </div>
      </form>

      <br />
      <table>
        <thead>
          <tr style={{ backgroundColor: "orange", color: "white" }}>
            <th>ລຳດັບ</th>
            <th>ຊື່ຮ້ານ</th>
            <th>active</th>
            <th>package</th>
            <th>ວັນທີເລີ່ມ</th>
            <th>ວັນໝົດອາຍຸ</th>
            <th>status</th>
            <th>control</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.active}</td>
              <td>{item.packageName}</td>
              <td>{moment(item?.start).format("DD/MM/YY, h:mm:ss a")}</td>
              <td>{moment(item?.expire).format("DD/MM/YY, h:mm:ss a")}</td>
              <td>{item.status}</td>
              <td>
                <div style={{ display: "flex", gap: 10 }}>
                  <Link to={`/packages/${item._id}`}>
                    <button>ຊື້ແພັກເກັດ</button>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Store;
