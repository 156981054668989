import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
function Package() {
  const Token = localStorage.getItem('Token')
  const { storeId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editedItem, setEditedItem] = useState({});
  const [creatPackage, setPackage] = useState({
    name: "",
    price: "",
    duration_day: "",
  });
  useEffect(() => {
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 minute
    return () => clearInterval(interval); // Cleanup function to clear interval
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.appzap.la:9100/packages",
      {
        headers: {
          Authorization: `STORE ${Token}`,
        },
      }
      );
      const jsonData = await response.json();
      setData(jsonData.map((item) => ({ ...item })));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = (index) => {
    setIsEditing(true);
    setEditIndex(index);
    setEditedItem({ ...data[index] });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditIndex(null);
    setEditedItem({});
  };

  const handleSaveEdit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `https://api.appzap.la:9100/package/${editedItem._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `STORE ${Token}` 
          },
          body: JSON.stringify(editedItem),
        }
      );
      if (response.ok) {
        // If the update is successful, fetch updated data
        fetchData();
      } else {
        console.error("Failed to update item");
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
    // Reset edit state and clear edited data
    setIsEditing(false);
    setEditIndex(null);
    setEditedItem({});
  };

  const handleDelete = async (index) => {
    try {
      const response = await fetch(
        `https://api.appzap.la:9100/package/${data[index]._id}`,
        {
          method: "DELETE",
          headers:{
            "Authorization": `STORE ${Token}` 
          }
        }
      );
      if (response.ok) {
        setData(data.filter((item, i) => i !== index));
      } else {
        console.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  

  const _onchang_create_edit_package = (event) => {
    const { name, value } = event.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  const _onchang_create_package = (event) => {
    const { name, value } = event.target;
    setPackage({ ...creatPackage, [name]: value });
  };

  const handleCreatepackage = async (event) => {
    event.preventDefault();
    
    try {
      const response = await fetch("https://api.appzap.la:9100/package", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `STORE ${Token}` 
        },
        body: JSON.stringify(creatPackage),
      });
      if (response.ok) {
        fetchData();
        setPackage({ name: "", price: "", duration_day: "" });
      } else {
        console.error("Failed to add item");
      }
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };
  const handleBuyPackage = async (packageId) => {
    try {
      const response = await fetch("https://api.appzap.la:9100/buypackage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `STORE ${Token}` 
        },
        body: JSON.stringify({
          packageId: packageId,
          storeId: storeId,
        }),
      });

      if (response.ok) {
        navigate("/");
      } else {
        console.error("Failed to add item");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container">     <div style={{fontSize:"25px", fontWeight:"bold"}}>ລາຍການແພັກເກັດ</div>
    <div style={{height:"2px", width:"100px",backgroundColor:"orange", marginBottom:"20px"}}></div>
      <br />
      <form onSubmit={handleCreatepackage}>
        <table>
          <thead>
          <tr style={{backgroundColor:"gray", color:"white"}}>
              <th>ຊື່ແພັກເກັດ</th>
              <th>ລາຄາ</th>
              <th>ຈຳນວນມື້</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  name="name"
                  value={creatPackage.name}
                  onChange={_onchang_create_package}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  name="price"
                  value={creatPackage.price}
                  onChange={_onchang_create_package}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  name="duration_day"
                  value={creatPackage.duration_day}
                  onChange={_onchang_create_package}
                  required
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <button type="submit">ເພິ່ມແພັກເກັດ</button>
      </form>
      <br />
      <div>

        <table>
          <thead>
          <tr style={{backgroundColor:"orange", color:"white"}}>
              <th>ລຳດັບ</th>
              <th>ຊື່ແພັກເກັດ</th>
              <th>ລາຄາ</th>
              <th>ຈຳນວນມື້</th>
              <th style={{ textAlign: "center" }}>Control</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.price}</td>
                <td>{item.duration_day}</td>

                <td>
                  {isEditing && editIndex === index ? (
                    <form onSubmit={handleSaveEdit}>
                      <label>
                        Name:
                        <input
                          type="text"
                          name="name"
                          value={editedItem.name}
                          onChange={_onchang_create_edit_package}
                        />
                      </label>
                      <label>
                        Price:
                        <input
                          type="text"
                          name="price"
                          value={editedItem.price}
                          onChange={_onchang_create_edit_package}
                        />
                      </label>
                      <label>
                        Days
                        <input
                          type="text"
                          name="duration_day"
                          value={editedItem.duration_day}
                          onChange={_onchang_create_edit_package}
                        />
                      </label>
                      <div style={{ display: "flex", gap: 10 }}>
                        <button type="submit">Save</button>
                        <button type="button" style={{backgroundColor:"gray"}} onClick={handleCancelEdit}>
                          Cancel
                        </button>
                      </div>
                    </form>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
                        <button
                        className="buy-button"
                        onClick={() => handleBuyPackage(item._id)}
                      >
                       ຊື້
                      </button>
                      <button
                        className="edit-button"
                        onClick={() => handleEdit(index)}
                      >
                        ແກ້ໄຂ
                      </button>

                    
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(index)}
                      >
                        ລົບ
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Package;
