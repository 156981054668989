import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const DropdownSTT = ({ onSelect }) => {
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [status, setStatus] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.appzap.la:9100/stores');
        const jsonData = await response.json();
        setStatus(
          jsonData.map((item) => ({
            value: item.status,
            label: item.status
          }))
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Initial fetch
    const interval = setInterval(fetchData, 60000); // Fetch data every 1 minute
    return () => clearInterval(interval); // Cleanup function to clear interval
  }, []);

  const handleChangeSTT = (selectedOptionSTT) => {
    setSelectedOptionStatus(selectedOptionSTT);
    onSelect(selectedOptionSTT);
  };

  return (
    <Select
      placeholder="ຮ້ານທີ່ ປິດ-ເປີດ ..."
      value={selectedOptionStatus}
      onChange={handleChangeSTT}
      options={status}
      isSearchable // Enables search functionality
    />
  );
};

export default DropdownSTT;
